
body {
  height: 100%;
  width: 100%;
  position: fixed;
  margin: 0px;

}

#container {
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
            position: absolute;
            overflow: hidden;
            font-size: 12px;
            font-family: Arial, sans-serif;

        }
       .panel {
          height:100%;
          display:flex;
          justify-content:center;
          align-items:center;
          background: linear-gradient(to bottom,#0E1721, #060A0E 50%, #04080B 50%, #030508);
          background-repeat: no-repeat;
          border:1px solid #555;
          box-sizing: border-box;
        }